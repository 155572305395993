import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Sites from '../components/Sites'
import LatestPosts from '../components/LatestPosts'
import Faq from '../components/Faq'
const IndexPage = ({ data }) => {
  const {
    allMdx: { edges: posts },
  } = data
  return (
    <Layout>
      <Seo
        title={`Situs Tips Judi Online Indonesia Gubelinlab`}
        description={`Gubelinlab Adalah Situs Tips Judi Online Indonesia Yang Memiliki Misi Untuk Membantu Bettor Indonesia Memenagkan Setiap Permainan Judi Online`}
        url={`https://gubelinlab.com/`}
        image={`https://gubelinlab.com/images/og_image.jpg`}
      />
      <Sites />
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-3 xl:pt-6 xl:pb-24">
          <div className="prose prose-purple prose-lg max-w-none">
            <h2>Situs Pilihan Bettor Jaman Sekarang</h2>
            <p>
              gubelinlab.com merupakan salah satu situs di internet yang telah dikenal oleh banyak
              sekali bettor pada saat ini dan pastinya hal tersebut disebabkan karena hal-hal
              menarik yang kami sediakan pada situs tersebut. Siapa sih yang tidak tertarik dengan
              hal-hal menarik yang tidak bisa didapatkan di situs lain ? Justru hal tersebut lah
              yang menjadi ketertarikan para bettor terhadap situs yang kami miliki ini, namun
              dengan banyaknya pemain yang telah mengenal situs. gubelinlab.com bukan berarti tidak
              ada bettor yang belum mengenalnya. Tentu saja masih ada pemain judi diluar sana yang
              belum mengetahui eksistensi kami. Oleh karena itu, kami ingin memberi penjelasan yang
              lebih jelas kepada para bettor disini.
            </p>
            <h2>Produk Tawaran Kami</h2>
            <p>
              Seperti yang kami katakan sebelumnya bahwa terdapat beberapa hal menarik yang kami
              sediakan di sini, lantas apa hal menarik yang kami maksudkan itu ? Hal-hal menarik
              yang kami tawarkan terdiri dari :
            </p>
            <ul>
              <li>Situs Judi Online</li>
              <li>Artikel Judi Online</li>
              <li>Akun VIP</li>
            </ul>
            <p>
              Nah, itu lah produk-produk andalan dari situs gubelinlab.com dan yang pastinya seluruh
              produk tersebut tidak perlu dibeli dengan uang sama sekali, semuanya gratis! Ya tujuan
              kami menawarkan hal seperti ini tentu agar para bettor yang tertarik dengan produk
              kami bisa lebih leluasa menggunakannya tanpa harus memikirkan harga.
            </p>
            <h2>Akun VIP, Situs Judi Online, Artikel Judi Online</h2>
            <p>
              Apalagi disini kami juga menyediakan Akun VIP yang dimana biasanya hal yang berkaitan
              dengan VIP itu pasti wajib bayar, namun di situs kami Anda tidak perlu membayar
              apapun. Hanya perlu melakukan pendaftaran pada situs rekomendasian kami dan Anda pun
              telah mendapatkan akun vip ini. Tentu juga selain menawarkan akun vip, kami juga
              memiliki produk tawaran lainnya yaitu situs judi online dan artikel judi online. Untuk
              situs yang kami tawarkan tentu bukan lah situs sembarangan melainkan situs judi online
              yang telah diakui oleh badan perjudian internasional sehingga Anda yang memilih situs
              ini pun tidak perlu khawatir lagi.
            </p>
            <p>
              Artikel judi online yang kami berikan kepada para bettor juga bukan lah artikel
              sembarangan/abal-abal melainkan merupakan artikel terbaik dan juga murni dari
              pengalaman kami dalam bermain game judi online. Tentu hal seperti ini sangat
              diperlukan karena para bettor tentu akan lebih mempercayai tips-tips bermain judi yang
              langsung dari ahlinya seperti kami.
            </p>
            <h2>Situs Judi Online Favorit Banyak Orang</h2>
            <p>
              Jadi bagi para bettor yang mungkin masih awam dalam permainan judi online dan merasa
              sangat sulit memperoleh kemenangan ataupun sulit menentukan situs mana yang hendak
              digunakan sebagai tempat bermain nantinya, maka kami sarankan kepada Anda untuk pilih
              lah situs kami. Kami juga bisa menjamin bahwa ketika Anda menggunakan produk yang
              tersedia di gubelinlab.com maka Anda akan lebih puas lagi dalam bermain game-game judi
              online.
            </p>
          </div>
        </div>
      </div>

      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3" />
        </div>
        <div className="relative max-w-8xl mx-auto">
          <div className="text-center">
            <h2 className="sm:text-lg sm:leading-snug font-semibold tracking-wide uppercase text-rose-600 mb-3">
              Update Tips Judi Online Terbaru
            </h2>
            <p className="text-3xl sm:text-5xl lg:text-6xl leading-none font-extrabold text-gray-900 tracking-tight mb-8">
              Jangan Lewatkan <br />
              Tips Terbaru Dari{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-br from-pink-500 to-rose-500">
                Gubelinlab
              </span>
            </p>
            <p className="max-w-4xl text-lg sm:text-2xl font-medium sm:leading-10 space-y-6 mb-6 mx-auto">
              Update Tips Judi Online Terbaru Dan Terbaik Yang Secara Eksklusif Kami Racik Untuk
              Membantu Anda Memenangkan Permainan{' '}
              <strong>Judi Slot , Judi Bola , Judi Poker Maupun Judi Casino Online</strong>
            </p>
          </div>
          <div className="mt-12 max-w-lg mx-auto grid gap-8 lg:grid-cols-2 lg:max-w-none">
            {posts.map(({ node }) => {
              const {
                fields: { slug },
                frontmatter: {
                  title,
                  description,
                  featured_image,
                  created_at,
                  updated_at,
                  hashtags,
                },
                id,
                timeToRead,
              } = node
              return (
                <LatestPosts
                  key={id}
                  title={title}
                  description={description}
                  hashtags={hashtags}
                  featuredImage={featured_image}
                  slug={slug}
                  date={created_at}
                  time={updated_at}
                  timeToRead={timeToRead}
                />
              )
            })}
          </div>
        </div>
      </div>
      <Faq />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageQuery {
    allMdx(sort: { fields: [frontmatter___created_at], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            description
            created_at(formatString: "dddd, DD MMM YYYY", locale: "id")
            updated_at
            featured_image
            hashtags
          }
          fields {
            slug
          }
          id
          timeToRead
        }
      }
    }
  }
`
